.cardStyle {
  border-radius: 10px;
  box-shadow: 3px 3px 3px 3px rgba(255, 255, 255, 0.1),
    0px 0px 0px 0px rgba(255, 255, 255, 0.1);
  transition: 0.4s;
}

.cardStyle:hover {
  transform: scale(1.05, 1.05);
  box-shadow: 3px 3px 3px 3px rgba(0, 0, 0, 0.25),
    0px 0px 0px 0px rgba(0, 0, 0, 0.22);
}
