html,
body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
li,
p {
  font-size: 1.1rem;
  line-height: 1.625;
  margin-top: 7px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.gradiantRed {
  /* background: #fc00ff;  
  background: -webkit-linear-gradient(
    to right,
    #00dbde,
    #fc00ff
  );  
  background: linear-gradient(
    to right,
    #00dbde,
    #fc00ff
  );  */
  background: #9cecfb; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #0052d4,
    #65c7f7,
    #9cecfb
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #0052d4,
    #65c7f7,
    #9cecfb
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.colorGradiant {
  /* background: linear-gradient(to right, #00dbde, #fc00ff); */
  background: linear-gradient(
    to right,
    #0052d4,
    #65c7f7,
    #9cecfb
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.textGradientColor {
  background: -webkit-linear-gradient(320deg, #0052d4 20%, #9cecfb 70%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.textGradientColorButton {
  background: -webkit-linear-gradient(320deg, #0052d4 20%, #9cecfb 70%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.textGradientColorBlackButton {
  background: -webkit-linear-gradient(320deg, #000000 20%, #434343 70%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.social-icons a {
  display: inline-block;
  height: 72px;
  width: 72px;
  color: #fff !important;
  border-radius: 100%;
  text-align: center;
  font-size: 1.4rem;
  line-height: 2.5rem;
  margin-right: 1rem;
}

h1.linear-wipe {
  background: linear-gradient(-45deg, #ef4444, #ef4444, #f59e0b, #f59e0b);
  background-size: 300%;

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animated_text 4s ease-in-out infinite;
  -moz-animation: animated_text 4s ease-in-out infinite;
  -webkit-animation: animated_text 4s ease-in-out infinite;
}

@keyframes animated_text {
  0% {
    background-position: 0px 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0px 50%;
  }
}

.backgroundColorGradient {
  background: #1e2136; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #1e2136,
    #1e2136,
    #1e2136
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #1e2136,
    #1e2136,
    #1e2136
  ); 
}